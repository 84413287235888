import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Link } from 'gatsby'
import { Global } from '@emotion/react'
import { useTranslation, Trans } from 'react-i18next'

import GlobalStyles from 'styles/GlobalStyles'
import { useAuth0 } from 'utils/react-auth0-spa'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/LottieShape'
import AbstractCongratulationsAnimation from 'components/Animations/AbstractCongratulationsAnimation'
import success from 'images/png/success.png'

const Success = () => {
  const ACCOUNT_PATH = '/account'
  const SUPPORT_EMAIL = 'support@fluentworlds.com'

  const { auth0Loading, getTokenSilently } = useAuth0()
  const { t, ready } = useTranslation('paymentSuccessPage')

  useEffect(() => {
    let stripeCustomerId = null

    if (typeof window !== 'undefined') {
      stripeCustomerId = window.location.search.split('=').pop()
    }

    const createTapfiliateConversion = (stripeCharge) => {
      const { chargeId, customerId, amount } = stripeCharge

      if (typeof window !== 'undefined') {
        window.tap('create', '13057-f3c134', { integration: 'stripe' })
        window.tap('conversion', chargeId, (amount / 100).toFixed(2), {
          customer_id: customerId,
        })
      }
    }

    const getChargeAndCreateTapfiliateConversion = async (stripeCustomerId) => {
      try {
        const accessToken = await getTokenSilently()

        const firebaseResponse = await axios({
          method: 'post',
          url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            query: `
            query {
              getLatestStripeCustomerCharge(input: {stripeCustomerId: "${stripeCustomerId}"}) {
                stripeChargeId
                stripeCustomerId
                amount
              }
            }
            `,
          },
        })

        if (firebaseResponse.data.data.getLatestStripeCustomerCharge) {
          createTapfiliateConversion(
            firebaseResponse.data.data.getLatestStripeCustomerCharge,
          )
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (!auth0Loading) {
      getChargeAndCreateTapfiliateConversion(stripeCustomerId)
    }
  }, [auth0Loading, getTokenSilently])

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
        <script
          src="https://script.tapfiliate.com/tapfiliate.js"
          type="text/javascript"
          async
        ></script>
        <script type="text/javascript">
          {`
            (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
              (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');

          `}
        </script>
      </Helmet>
      <Global styles={GlobalStyles} />
      <StripeCheckoutRedirectContainer>
        <ShapeContainer>
          <LottieShape
            size={500}
            animationData={AbstractCongratulationsAnimation}
          />
        </ShapeContainer>
        {!ready ? null : (
          <StripeCheckoutContentContainer>
            <StripeCheckoutRedirectHeader>
              <StripeCheckoutImage src={success} alt="success" />
              {t('thankYou')}
            </StripeCheckoutRedirectHeader>
            <StripeCheckoutRedirectDescription>
              {t('startExploring')}
            </StripeCheckoutRedirectDescription>
            <StripeCheckoutRedirectDescription>
              <Trans i18nKey="paymentSuccessPage:haveQuestions">
                <a
                  css={`
                    color: rgb(63, 129, 238);
                  `}
                  href="mailto:support@fluentworlds.com"
                  aria-label="EmailSupport"
                >
                  {SUPPORT_EMAIL}
                </a>
              </Trans>
            </StripeCheckoutRedirectDescription>
            <Link to={ACCOUNT_PATH}>
              <StripeCheckoutRedirectButton>
                {t('goToAccount')}
              </StripeCheckoutRedirectButton>
            </Link>
          </StripeCheckoutContentContainer>
        )}
      </StripeCheckoutRedirectContainer>
    </>
  )
}

export default Success
